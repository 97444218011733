import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import SideBar from "../SideBar/SideBar";
import first from "../../asset/parents/first.jpeg";
import second from "../../asset/parents/second.jpeg";
import third from "../../asset/parents/third.jpeg";
import fourth from "../../asset/parents/fourth.jpeg";
import fifth from "../../asset/parents/fifth.jpeg";
import Sixth from "../../asset/parents/Sixth.jpeg";
import Seventh from "../../asset/parents/Seventh.jpeg";

// import s1 from "../../asset/students/s1.jpg";
// import s2 from "../../asset/students/s2.jpg";
// import s3 from "../../asset/students/s3.jpg";
import paTopIcon from "../../asset/newImg/pa.png";
import "./Content.css";

function Parents(props) {
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div className="contentmain_cont">
        <div style={{ width: "min-content" }}>
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div>
          {/* parents' voice */}
          <div className={`f-2 f-500 mb-3`} style={{ color: "#1FDC00ED" }}>
            <img
              src={paTopIcon}
              style={{ width: "4rem", marginRight: "1rem" }}
            />
            Parents' Voice
          </div>
          <div className={`parents_voice`} style={{ marginTop: "1.5rem" }}>
            <div className="parentimage">
              <img
                src={first}
                style={{
                  width: "10rem",
                  height: "10rem",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                  border: "3px solid #1FDC00F5",
                }}
              />
            </div>
            <div className={`voice_text`}>
              <p style={{ marginTop: "1rem" }}>
                <b>Sahar Khan(LKG-A) & SIDRA KHAN (LKG –B)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                Teachers of this school are really hard working, cooperative and
                dedicated towards their duties.
              </p>
            </div>
          </div>
          <div className="parents_voicee">
            <div className="voice_textt">
              <p style={{ marginTop: "1rem" }}>
                <b>Anas Khan(LKG-B) & Sana (LKG-B)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I am very happy with the performance of school because my
                daughter & son are doing very well. The motivation of principal
                sb(Mr. Mohd. Aslam) has been inspirational for me & my children.
              </p>
            </div>
            <div className="parenttimage">
              <img
                src={second}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <div className="parentimage">
              <img
                src={third}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
            <div className="voice_text">
              <p style={{ marginTop: "1rem" }}>
                <b>Haseen,(Class III)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                The School is doing very well in academics & other school
                activities. I am satisfied with school teaching learning
                activities.
              </p>
            </div>
          </div>
          <div className="parents_voicee">
            <div className="voice_textt">
              <p style={{ marginTop: "1rem" }}>
                <b>Haseen, Class IV</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                The school takes great care in all areas along with studies. The
                entire staffs of the school are cooperative and hardworking.
              </p>
            </div>
            <div className="parenttimage">
              <img
                src={fourth}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
          </div>
          {/*  */}
          <div className="parentsvoicee">
            <div className="parentimage">
              <img
                src={fifth}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
            <div className="voice__textt">
              <p style={{ marginTop: "1rem" }}>
                <b>AASTIK (Class –II)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I am part of this school but I think I cannot find better than
                this in Mewat.
              </p>
              <p>Great team ~ Great school.</p>
            </div>
          </div>
          <div className="parents_voicee">
            <div className="voice_textt">
              <p style={{ marginTop: "1rem" }}>
                <b> Pari, Class VII and Alshiba Class II</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                The School is doing very well in academics & other school
                activities. I am satisfied with school teaching learning
                activities.
              </p>
            </div>
            <div className="parenttimage">
              <img
                src={Sixth}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
          </div>
          <div className="parentsvoicee">
            <div className="parentimage">
              <img
                src={Seventh}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
            <div className="voice__textt">
              <p style={{ marginTop: "1rem" }}>
                <b>Dilshad Class V ,Dilshana Class V ,Wazib Class VIII</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                The School is doing very well in academics & cultural
                activities. We are very happy s and highly motivated by
                Principal Sir, Mohd. Asalm , for importance of education
                especially girls in PTM.
              </p>
              <p>Great team ~ Great school.</p>
            </div>
          </div>
          {/* students' voice */}
          {/* <div className={`f-2 f-500 mt-5 `} style={{ color: "#1FDC00ED" }}>
            <img
              src={paTopIcon}
              style={{
                width: "4rem",
                marginRight: "1rem",

              }}
            />
            Students' Voice
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <div className="parentimage">
              <img
                src={s1}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
            <div className="voice_text">
              <p style={{ marginTop: "1rem" }}>
                <b>Kushleen Kaur (XII C)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I am so grateful for being a part of Hermann Gmeiner School,
                Faridabad. The teachers are kind and supportive. They have
                always guided me and gave me the strength I needed to achieve my
                goals. The school has given me many opportunities to show my
                best which increased my confidence. I will always miss my
                school.”
              </p>
            </div>
          </div>
          <div className="parents_voicee">
            <div className="voicetext">
              <p style={{ marginTop: "1rem" }}>
                <b>Nikitaa Shukla XII D </b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                The school has the friendly atmosphere and a best place for
                enhancing students skills. Teachers are very supportive.I feel
                proud to be a part of Hermann Gmeiner School Faridabad since
                2010.
              </p>
            </div>
            <div className="parentimage">
              <img
                src={s2}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
          </div>
          <div className="parents__voice">
            <div className="parentimage">
              <img
                src={s3}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
            <div className="voicetext">
              <p style={{ marginTop: "1rem" }}>
                <b>Eshaa Shukla XII B</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I am attending this school since 2010 and have only great things
                to say about my experience. The teachers are amazing,
                understanding and supportive.They teach their students very
                passionately and each child is treated with care.
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Parents;

import axios from "axios";
import React, { useState } from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../NavBar/Navbar";
import NavbarMenu from "../../NavbarMenu/NavbarMenu";
import {
  BUTTON_TEXT,
  FORM,
  HEADERS,
  NEXT_STEP,
  PREV_STEP,
  STEPS,
  SUBMIT_HEADER,
} from "./contants";
import { Input } from "./Input";
import { Progress } from "./Progress";

export const NewRegistration = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    console.log(e);
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  const [step, setStep] = useState(STEPS.STEP_1);
  const [form, setForm] = useState({});
  console.log(form);
  const onChange = (e) => {
    const { value, name } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  const onItemClick = ({ fieldName, value }) => {
    setForm((prev) => ({ ...prev, [fieldName]: value }));
  };

  const incrementStep = () => {
    const nextSteps = NEXT_STEP[step];
    if (nextSteps !== STEPS.SUBMIT) {
      setStep(nextSteps);
      return;
    }
    setStep(STEPS.STEP_1);
  };
  const derementStep = () => {
    const nextSteps = PREV_STEP[step];
    if (nextSteps !== STEPS.SUBMIT) {
      setStep(nextSteps);
      return;
    }
    setStep(STEPS.STEP_1);
  };

  const submitForm = async (e) => {
    const form = new FormData();
    form.append("reg_json", form);
    form.append("reg_json", form);
    form.append("reg_json", form);
    e.preventDefault();
    const data = await axios.post(
      `https://cors-fix-kylo.herokuapp.com/https://eschool.appsosindia.org/views/manage_student/php/json_test.php`
    );
    incrementStep();
  };

  return (
    <div>
      <div className="home_main" onScroll={handleScroll}>
        <Navbar isScrolled={isScrolled} handleClick={handleClick} />
        <NavbarMenu isShow={isShow} handleClick={handleClick} />
        <div className="pt-5 mt-5 flex-center d-flex justify-content-center align-items-center">
          <div>
            <div className="flex-center d-flex justify-content-center py-10 mt-5">
              <Progress currentStep={step} />
            </div>
            <form
              onSubmit={submitForm}
              className="d-flex my-5 justify-content-center align-items-center flex-column"
            >
              <div className="row d-flex justify-content-center">
                {step !== STEPS.STEP_8 &&
                  FORM[step].map((item) => (
                    <Input
                      key={item.name}
                      {...item}
                      onChange={onChange}
                      form={form}
                      onClick={onItemClick}
                    />
                  ))}
              </div>
              {step === STEPS.STEP_8 && (
                <div>
                  <div className="flex-center ">
                    {/* <Success className="flex-center">&#10003;</Success> */}
                  </div>
                  <h4>
                    <h5 className="title text-center">
                      {SUBMIT_HEADER[step].title.replace(
                        form.fullName || "HGS"
                      )}
                    </h5>
                    <p className="sub-title text-mute text-center">
                      {SUBMIT_HEADER[step].subTitle}
                    </p>
                  </h4>
                </div>
              )}
              <div className="flex-center">
                {step !== STEPS.STEP_1 && (
                  <button
                    type="button"
                    onClick={derementStep}
                    className="apply-btn cursor my-2 mx-2 "
                  >
                    {step !== STEPS.STEP_1 && BUTTON_TEXT.prev}
                  </button>
                )}
                <button
                  type={step === STEPS.STEP_8 ? "submit" : "button"}
                  className="apply-btn cursor my-2"
                  onClick={incrementStep}
                >
                  {step === STEPS.STEP_8
                    ? BUTTON_TEXT.launch
                    : BUTTON_TEXT.default}
                </button>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
